import React, { useState, useEffect } from "react";
import TitleOnlySave from '../../components/TitleOnlySave';
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';
import { updateProduct } from '../../../api/APIPost'
import es from "../../../es.json"
// import './ModalCheck.css';

function ModalCheck(props) {

  const [open,setOpen] = useState(false);

  const checkMargins = (data) => {
    const marginsdata = data.map((item) => {
      return {
        margin: item.attributes.margin,
        //marginWD: item.attributes.marginWD,
        name: item.attributes.field_name
      };
    });

    return marginsdata;
  }
  
  const margins = checkMargins(props.data);
  
  
  const handleMargins = (margins) => {
    console.log("Margins", margins);
    margins.map((margin) => {
      // Lógica para margin
      if (localStorage.getItem('current_role') !== 'admin' && margin.margin < 0) {
        toast.info(`Cambió ${margin.name} y se solicitaron cambios y están esperando la aprobación de un administrador`);
      } else if (localStorage.getItem('current_role') !== 'admin' && margin.margin >= 0 && margin.margin < 15) {
        toast.info(`Cambió ${margin.name} y se solicitaron cambios y están esperando la aprobación de un administrador`);
      } else if (localStorage.getItem('current_role') !== 'admin' && localStorage.getItem('current_role') !== 'supervisor' && margin.margin >= 15 && margin.margin < 20) {
        toast.info(`Cambió ${margin.name} y se solicitaron cambios y están esperando la aprobación de un supervisor`);
      } else {
        toast.success(`Cambiaste ${margin.name} y se aplicaron tus cambios.`);
      }
    });
  }
  /*
  const handleMarginsWD = (margins) => {
    margins.map((marginWD) => {
      // Lógica para marginWD
      if (localStorage.getItem('current_role') !== 'admin' && marginWD.marginWD < 0) {
        toast.info(`ESTE Cambió ${marginWD.name} (margen con descuento) y se solicitaron cambios y están esperando la aprobación de un administrador`);
      } else if (localStorage.getItem('current_role') !== 'admin' && marginWD.marginWD >= 0 && marginWD.marginWD < 15) {
        toast.info(`ESTE Cambió ${marginWD.name} (margen con descuento) y se solicitaron cambios y están esperando la aprobación de un administrador`);
      } else if (localStorage.getItem('current_role') !== 'admin' && localStorage.getItem('current_role') !== 'supervisor' && marginWD.marginWD >= 15 && marginWD.marginWD < 20) {
        toast.info(`ESTE Cambió ${marginWD.name} (margen con descuento) y se solicitaron cambios y están esperando la aprobación de un supervisor`);
      } else {
        toast.success(`ESTE Cambiaste ${marginWD.name} (margen con descuento) y se aplicaron tus cambios.`);
      }
    });
  }
  */
  const send_data = (data, sku, id_btn_edit) => {
    updateProduct(data, sku).then(response => {
      document.getElementById(id_btn_edit).click();
      if (margins.length > 0) {
        handleMargins(margins);
        //handleMarginsWD(margins);
      }

      setTimeout(() => {
        props.getProductData(sku);
      }, 2000);
    })
    .catch(err => {
      toast.error('No se ha podido mandar los datos, vuelva a intentarlo');
    });
  }

  const check_data = () => {
    var data = props.data;
    delete data[0]?.attributes['margin']
    props.changeStateModalChech();
    send_data(data, props.sku, props.id_btn_edit)
  }

  //console.log(props.data)

  let tableCheck = props.data.map((product, i) => {
    let _ = require('lodash')
    if(product.attributes.title !== null){
      let title = product.attributes.title !== undefined ?  product.attributes.title : null
      let old_value = product.attributes.old_value !== undefined ? product.attributes.old_value : []
      let new_value = (product.attributes.new_value !== undefined) ? product.attributes.new_value : []
      if(product.attributes.field_name === 'principal_recipe' || product.attributes.field_name === 'alternative_recipe' ){
        if(_.isEqual(old_value, new_value)){ return }
        let placeholder_old_value = ''
        let placeholder_new_value = ''
        old_value.map((element) => placeholder_old_value += `${element['sku']} | ${element['description']} | ${element['line']} | ${element['quantity']} <br>`)      
        new_value.map((element) => placeholder_new_value += `${element['sku']} | ${element['description']} | ${element['line']} | ${element['quantity']} <br>`)
        old_value = placeholder_old_value
        new_value = placeholder_new_value
      }else if( product.attributes.field_name === 'product_list'){
        if(_.isEqual(old_value, new_value)){ return }
        let placeholder_old_value = ''
        let placeholder_new_value = ''
        old_value.map((element) => placeholder_old_value += `${element['sku']} | ${element['description']} <br>`)      
        new_value.map((element) => placeholder_new_value += `${element['sku']} | ${element['description']} <br>`)
        old_value = placeholder_old_value
        new_value = placeholder_new_value
      }else if(product.attributes.field_name === 'configurable_attributes'){
        if(_.isEqual(old_value, new_value)){ return }
        let placeholder_old_value = ''
        let placeholder_new_value = ''
        old_value.map((key) => placeholder_old_value += `${es['ProductItem'][key]}<br>`)      
        new_value.map((key) => placeholder_new_value += `${es['ProductItem'][key]}<br>`)
        old_value = placeholder_old_value
        new_value = placeholder_new_value
      }
  
      return(
        <tr key={i}>
          <th scope='row'>{title}</th>
          <td dangerouslySetInnerHTML={{__html: old_value}} />
          <td dangerouslySetInnerHTML={{__html: new_value}} />
        </tr>
      )
    }
    else{
      return null
    }
  });

  return(
    <div>
      <Popup
        open={props.modalcheck}
        closeOnDocumentClick
      >
       <div>
          <TitleOnlySave title={'Comprobar datos cambiados de ' + props.sku} check_data={check_data}/>
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'>Campo</th>
                <th scope='col'>Valor anterior</th>
                <th scope='col'>Valor nuevo</th>
              </tr>
            </thead>
            <tbody>
              {tableCheck}
            </tbody>
          </table>
          <button className='btn btn-danger' onClick={props.changeStateModalChech}>Salir</button>
        </div>
      </Popup>
    </div>
  );
}

export default ModalCheck;
