import React from 'react';
import { toast } from 'react-toastify';
import { setProductAccept } from '../../api/APIPost'
import es from '../../es.json'

function ButtonAdd(props){
  if(props.pending_change){
    return(
      <td>
        <button className={`btn btn-${props.class} btn-sm`} type='button' onClick={ () => { props.changeProduct(props.product, props.type)} }>
          {props.name}
        </button>
      </td>
    )
  }else{
    return null
  }
}
class ProductItem extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedId: null,
    }

    this.changeProduct = this.changeProduct.bind(this);
    this.convertDateUTC = this.convertDateUTC.bind(this);
    this.translateText = this.translateText.bind(this);
  }

  changeProduct(data, change){
    setProductAccept(data, change).then( response => {
      if(response.status===204){
        this.props.getProductsData(1);
        toast.success('Cambio realizado');
      }else if(response.status===403){
        toast.error('No tienes permisos para realizar esta acción');
      }else{
        toast.error('No se ha podido cambiar los datos, vuelva a intentarlo');
      }
    });
  }

  convertDateUTC(TimeUTC){
    if(TimeUTC!==undefined){
      let date = new Date(TimeUTC)
      // var date = TimeUTC.split(' ')[0]
      // var hour = TimeUTC.split(' ')[1]
      // var day = date.split('-')[2]
      // var mouth = date.split('-')[1]
      // var year = date.split('-')[0]
      return `${date.toLocaleString()}`
    }else{
      let notTime = '-'
      return notTime
    }
  }

  translateText(text){
    let t = es['ProductItem']
    if(t[text]){
      return t[text]
    }else{
      return text
    }
  }

  render() {
    let t = es['ProductItem'];
    const {updated_by, sku, created_at, field_name, old_value, new_value, approve_role} = this.props.product.attributes
    const productType = this.props.product.type
    if(this.props.product===undefined){
      return (<h2>Cargando...</h2>);
    }
    return(
      <tr>
        <td className='small' align='center' id='checkbox'>
          <input type='checkbox' className='' id={this.props.product.id} />
        </td>
        <td className='small'>{sku}</td>
        <td className='small'>{this.translateText(productType)}</td>
        <td className='small'>
          {this.translateText(field_name)}
        </td>
        <td className='small'>{updated_by}</td>
        <td className='small'>{approve_role?.toUpperCase()}</td>
        <td className='small' dangerouslySetInnerHTML={{__html: old_value}} />
        <td className='small' dangerouslySetInnerHTML={{__html: new_value}} />
        <ButtonAdd pending_change={this.props.pending_change} className={'success'} changeProduct={this.changeProduct} product={this.props.product} type={'approve'} 
        name='Aceptar' />
        <ButtonAdd pending_change={this.props.pending_change} className={'danger'} changeProduct={this.changeProduct} product={this.props.product} type={'decline'} 
        name='Rechazar' />
        <td className='small'>{this.convertDateUTC(created_at)}</td>
      </tr>
    );
  }
}
export default ProductItem;
